import React, {useState} from "react"
import Layout from "../components/layout"
import {graphql, Link} from "gatsby";
import Img from "gatsby-image"
import Map from "../components/map";
import SEO from "../components/seo";

export default ({
                  data: {
                    datoCmsCity: c,
                    allDatoCmsShoppingarea: sas,
                    allDatoCmsDesigner: ds,
                    allDatoCmsShop: ss,
                    allDatoCmsStylist: stylists
                  }
                }) => {
  const [showAll, setShowAll] = useState(false)
  const [shops, setShops] = useState(ss.edges.map(v => v.node));
  const [designerId, setDesignerId] = useState(null)
  const designerMap = Object.assign({}, ...ds.edges
    .map(v => v.node)
    .map(d => ({[d.id]: d}))
  )
  return (
    <Layout>
      <SEO title={`Shopping City ${c.name}`} seo={c.seoMetaTags} image={c.imagetop.url}/>
      <section className="flex flex-wrap">
        <div className="flex flex-1 flex-col items-end px-4 pt-4 md:pt-24 mb-4">
          <span className="font-title  text-lg">{c.name}</span>
          <span className='font-sans font-light text-right' dangerouslySetInnerHTML={{__html: c.short}}/>
        </div>
        <Img className='flex-1 mr-2 md:mr-8 my-4' fluid={c.imagetop.fluid} alt={`Shopping City ${c.name}`}/>
      </section>
      <Link to='/booking/' className="block w-full bg-black p-8">
        <div className='w-full font-sans font-light text-white uppercase w-full text-center tracking-wide text-lg'>
          Book your shopping trip now!
        </div>
      </Link>
      <Map location={c.location} zoom={11} points={shops.filter(v => v.active)}/>
      <section className="w-full bg-black p-8">
        {/*<div*/}
        {/*  className='font-sans font-light text-white uppercase w-full text-center tracking-wide text-lg'>Request*/}
        {/*  an online consultation*/}
        {/*</div>*/}
      </section>
      <section className="flex flex-wrap py-2">
        <div className="flex-grow md:flex-1 flex flex-col py-2 px-2 lg:pl-16 lg:pr-4">
          <span className="font-title mb-4">Shops</span>
          {shops
            .slice(0, showAll ? 1000 : 10)
            .map(s => {
              return <div className='flex flex-col' key={s.id}>
                <div className="flex justify-between items-start">
                  <div className="flex flex-col">
                    <span className='font-sans font-light'>{s.name}</span>
                    <span className='font-sans font-light text-gray-700'>{s.address}</span>
                  </div>
                  <button
                    className={"flex-none focus:outline-none py-1 px-2 text-white font-title " + (s.active ? 'bg-red-600' : 'bg-black')}
                    onClick={() => {
                      setShops(shops.map(shop => {
                        if (shop.id === s.id) {
                          return {...shop, active: !(shop.active || false)}
                        } else {
                          return shop;
                        }
                      }))
                    }}>Show on map
                  </button>
                </div>
                <hr className="border mx-0  my-2 border-black"/>
              </div>;
            })
          }
          <button className="focus:outline-none py-1 px-2 bg-black text-white font-title"
                  onClick={() => setShowAll(!showAll)}>{showAll ? 'Show less' : 'Show all'}</button>
        </div>
        <div className="md:flex-1 flex flex-col py-2 px-2 lg:px-8">
          <span className="font-title mb-4">Our travel partners in {c.name}</span>
          <div className="flex flex-wrap items-center justify-between p-2 md:p-0">
            {c.partners
              .map(v => <Img className='m-1' key={v.id} fixed={v.logo.fixed}/>)
            }
          </div>
          <span className="font-title my-4">Stylists</span>
          <div className="flex flex-wrap justify-center w-full">
            {stylists
              .edges
              .map(v => v.node)
              .filter(v => v.avatar)
              .map(d => <Link to={'/stylists/' + d.originalId + '/'} className='flex-1 m-2' style={{maxWidth: 200}}
                              key={d.id}>
                <Img className="" style={{minWidth: 100}} fixed={d.avatar.fixed}/>
                <div className='font-title text-left w-full text-sm'>{d.name}</div>
              </Link>)
            }
          </div>

        </div>
      </section>
      <section className="w-full bg-black p-8">
        <div
          className='font-sans font-light text-white uppercase w-full text-center tracking-wide text-lg'>Top
          shopping areas in {c.name}</div>
      </section>
      <section className="flex flex-wrap">
        <div className="md:flex-1 py-2 lg:py-8 px-2 lg:px-4">
          <Img className='lg:mx-16' fluid={c.imagemiddle.fluid} alt={`Shopping City ${c.name}`}/>
          <div className='px-2 lg:mx-16 my-4 font-sans font-light text-center'
               dangerouslySetInnerHTML={{__html: c.description}}/>
        </div>
        <div className="md:flex-1">
          <div className="flex flex-col py-4 lg:py-8 px-2 lg:px-32 h-full">
            {sas
              .edges
              .map(v => v.node)
              .map(sa => <div className='flex flex-col' key={sa.id}>
                <span className='font-sans'>{sa.name}</span>
                <span className='font-sans font-light text-gray-800' dangerouslySetInnerHTML={{
                  __html: sa
                    .description
                    .replace(/<\/?[^>]+(>|$)/g, "")
                }}/>
                <hr className="border mx-0  my-2 border-black"/>
              </div>)
            }
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center my-4">
        <span className="font-title my-4">Designers in {c.name}</span>
        <div className="flex flex-wrap justify-center">
          {ds
            .edges
            .map(v => v.node)
            .map(d => <div className='m-2 cursor-pointer' style={{width: 100}} key={d.id}
                           onClick={() => setDesignerId(designerId === d.id ? null : d.id)}>
              {d.image == null ? null : <Img className="p" fixed={d.image.fixed}/>}
              <div
                className={'font-title text-center w-full text-sm' + (designerId === d.id ? ' text-red-600' : '')}>{d.name}</div>
            </div>)
          }
        </div>
        <div
          className='font-sans font-light bg-gray-200 mx-2 md:mx-32 p-2'>{designerId && designerMap[designerId].description}</div>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query GetCityLocal($slug:String) {
        datoCmsCity(slug: {eq: $slug}) {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            slug
            name
            short
            description
            imagetop {
                fluid(maxWidth: 1000, imgixParams: {fit: "crop", w:"1000", h:"600"}) {
                    ...GatsbyDatoCmsFluid
                }
                url
            }
            imagemiddle {
                fluid(maxWidth: 1000, imgixParams: {fit: "crop", w:"1000", h:"600"}) {
                    ...GatsbyDatoCmsFluid
                }
            }
            location {
                longitude
                latitude
            }
            partners{
                id
                name
                logo{
                    fixed(width:80, imgixParams: {auto: "compress,format"}) {
                        ...GatsbyDatoCmsFixed
                    }
                }
            }
        }
        allDatoCmsShoppingarea(filter:{city:{slug:{eq:$slug}}}) {
            edges {
                node {
                    id
                    name
                    description
                }
            }
        }
        allDatoCmsDesigner(filter: {city: {slug: {eq: $slug}}}) {
            edges {
                node {
                    id
                    name
                    description
                    image {
                        fixed(width:100, height: 100, imgixParams: {fit: "crop"}) {
                            ...GatsbyDatoCmsFixed
                        }
                    }
                }
            }
        }
        allDatoCmsStylist(sort: {fields:[name]}, filter: {city: {slug: {eq: $slug}}}) {
            edges {
                node {
                    id
                    originalId
                    name
                    avatar {
                        fixed(width:100, height: 100, imgixParams: {fit: "crop"}) {
                            ...GatsbyDatoCmsFixed
                        }
                    }
                }
            }
        }
        allDatoCmsShop(sort: {fields:[name]}, filter: {city: {slug: {eq: $slug}}}) {
            edges {
                node {
                    id
                    name
                    address
                    location{
                        latitude
                        longitude
                    }
                }
            }
        }
    }
`
